import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getQueryString } from '@shein/common-function'
import { checkGoodsStock } from '../common/utils'
daEventCenter.addSubscriber({ modulecode: '1-65-1' })

class AdvertisingAnalysis {
  constructor() {
    this.pageType = '-'
    this.goodsId = '-'
    this.tspId = '-'
    this.crowdId = '-'
    this.goodsIdType = '1'
  }
  init({ hooksGoods }) {
    if (typeof window === 'undefined') return
    this.pageType = getQueryString({ key: 'page_type' }) || '-'
    this.goodsId = getQueryString({ key: 'goods_id' }) || '-'
    this.tspId = getQueryString({ key: 'tsp_id' }) || '-'
    this.crowdId = getQueryString({ key: 'crowd_id' }) || '-'
    this.goodsIdType = hooksGoods?.goods_id && hooksGoods?.goods_name ? checkGoodsStock(hooksGoods) ? '1' : '0' : '-'
  }
  // 重置PageInfo
  resetPageInfo() {
    window.SaPageInfo = {
      page_id: 3437,
      page_name: 'page_advertising',
      page_param: {
        category_id: ''
      },
      start_time: new Date().getTime()
    }
    sa('set', 'setPageData', window.SaPageInfo)
  }
  // pv
  sendPV() {
    // daEventCenter.triggerNotice({
    //   daId: '1-65-1-1',
    //   extraData: {
    //     pagetype: this.pageType,
    //     goods_id: this.goodsId,
    //     tsp_id: this.tspId,
    //     crowd_id: this.crowdId,
    //     goods_id_type: this.goodsIdType
    //   }
    // })
    window.SaPageInfo = {
      page_id: 3437,
      page_name: 'page_advertising',
      page_param: {
        pagetype: this.pageType,
        goods_id: this.goodsId,
        tsp_id: this.tspId,
        crowd_id: this.crowdId,
        goods_id_type: this.goodsIdType
      },
      start_time: new Date().getTime()
    }
    sa('set', 'setPageData', window.SaPageInfo)
    // sa('send', 'pageEnter', {}) // 在public/src/pages/user/analysis/sa.js触发过了
  }
  // Tab点击
  clickRecommendTabs({ tab = 0 }) {
    daEventCenter.triggerNotice({
      daId: '1-65-1-5',
      extraData: {
        tab
      }
    })
  }
}

export default new AdvertisingAnalysis()
export const HOOK_GOOD_CLICK_ID = '2-3-26' // 钩子商品-点击id
export const HOOK_GOOD_EXPOSE_ID = '2-3-25' // 钩子商品-曝光id
