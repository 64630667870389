<template>
  <section
    ref="MINI_DETAIL_CONTAINER"
    class="mini-detail__container"
    :data-index="index"
  >
    <div class="mini-detail__box">
      <!-- Gallery -->
      <MiniGallery
        ref="MINI_GALLERY_REF"
        :config="config"
        :constant-data="constantData"
        :gallery-imgs="gallery"
        :show-belt="showBelt"
        :show-promo-label="showPromoLabel"
        :show-brand="showBrand"
        :brand-logo-data="brandLogoData"
        :promo-label-src="promoLabelSrc"
        :language="language"
        :cur-data="curData"
        :pretreat-info="pretreatInfo"
        :is-sold-out="isSoldOut"
        :index="index"
        :lazy-force-show="!index"
        :retention-config="retentionConfig"
        :scroll-fix="scrollFix"
        @click.native="clickGoodsHandle"
      />

      <!-- Goods Info -->
      <MiniIntro
        :cur-data="curData"
        :language="language"
        :cur-price="curPrice"
        :constant-data="constantData"
        :pretreat-info="pretreatInfo"
        :is-sold-out="isSoldOut"
        :config="config"
        :retention-config="retentionConfig"
        :shein-club-info="sheinClubInfo"
        @click.native="clickGoodsHandle"
      />

      <template v-if="showColorSize">
        <!-- Color -->
        <template v-if="showColor">
          <MiniColor
            :constant-data="constantData"
            :related-color="relatedColor"
            :cur-data="curData"
            :config="config"
            :index="index"
            :lazy-force-show="!index"
            @updateGoods="getProduct"
          />
        </template>
        <!-- 尺码 -->
        <template v-if="showSize">
          <MiniSize
            :cur-data="curData"
            :lazy-force-show="!index"
            :lazy-wrap-options="miniSizeLazyHeight"
            :external-size-info-index="externalSizeInfoIndex"
            @sizeClick="sizeClickHandle($event, 'page')"
          />
        </template>
      </template>
      
      <!-- 加车按钮 -->
      <MiniAddBtn
        :config="config"
        :cur-data="curData"
        :language="language"
        :wish-status="wishStatus"
        :is-sold-out="isCurrentSoldOut"
        :external-size-info-index="externalSizeInfoIndex"
        :sku-info="skuInfo"
        @sizeClick="sizeClickHandle"
        @updateGoods="getProduct"
        @updateWishStatus="updateWishHandle"
        @addToBagSuccess="addToBagSuccess"
      />
    </div>

    <!-- sold out -->
    <div
      v-if="isSoldOut"
      class="mini-detail__sold"
      @click.prevent="clickGoodsHandle"
    >
      <div class="sold-tips">
        <i class="suiiconfont sui_img_soldout sold-tips__icon"></i>
        <span class="sold-tips__text">{{ language.SHEIN_KEY_PWA_14987 }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import MiniIntro from './components/MiniIntro'
import MiniAddBtn from './components/MiniAddBtn'
import lazyComponent from 'public/src/services/lazyComponent'
import { getProductDetailUrl } from 'public/src/pages/goods_detail/utils/common.js'
import mixin from './mixin'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getLocateLabelsAna } from 'public/src/pages/components/product/item_v2/analysis/locateLabelsAna'

daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '2-26' })
const components = [
  {
    name: 'MiniGallery',
    style: { height: '9.2rem' },
    componentFactory: () => import('./components/MiniGallery')
  },
  {
    name: 'MiniColor',
    style: { height: '1.2rem' },
    componentFactory: () => import('./components/MiniColor')
  },
  {
    name: 'MiniSize',
    style: { height: '1.66rem' },
    componentFactory: () => import('./components/MiniSize')
  }
]

export default {
  name: 'MiniDetail',
  components: {
    MiniIntro,
    MiniAddBtn,
    ...components.reduce((componentsResult, curr) => {
      componentsResult[curr.name] = lazyComponent({
        ...curr,
        options: {
          style: curr.style
        }
      })
      return componentsResult
    }, {})
  },
  mixins: [mixin],
  props: {
    locals: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => {}
    },
    productInfo: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    bigCardStyle: {
      type: String,
      default: ''
    },
    sheinClubInfo: {
      type: Object,
      default: () => {}
    },
    goodsItemInfo: {
      type: Object,
      default: () => {}
    },
    scrollFix: {
      type: Function,
      default: function() {}
    }
  },
  data() {
    return {
      skuInfo: {},
      mallStock: null,
      currentGoodsId: '',
      externalSizeInfoIndex: {},
      goodsItemInfoSer: null,
      wishStatusMaps: {}
    }
  },
  computed: {
    curData() {
      const { relatedColor } = this
      let result = this.productInfo
      if (relatedColor.length && this.currentGoodsId && this.currentGoodsId != this.productInfo?.goods_id) {
        result = this.relatedColor?.find(_ => _.goods_id == this.currentGoodsId)
      }
      return this.formatDetail(result)
    },
    wishStatus() {
      const { wishStatusMaps = {}, currentGoodsId } = this
      return wishStatusMaps[currentGoodsId] || null
    },
    curPrice() {
      const { skuInfo, curData } = this
      if (skuInfo?.price) {
        return skuInfo?.price
      }
      return curData
    },
    relatedColor() {
      return this.productInfo?.relatedColor || []
    },
    showColor() {
      return !!this.relatedColor.length
    },
    showSize() {
      return !!this.curData?.sale_attr_list
    },
    isSoldOut() {
      const { relatedColor = [] } = this
      if (!relatedColor?.length) {
        return (
          this.curData.is_on_sale == 0 ||
        this.curData.stock == 0 ||
        this.curData.sale_percent == 1
        )
      } else {
        return relatedColor.every(_ => _.is_on_sale == 0 || _.stock == 0 || _.sale_percent == 1)
      }
    },
    isCurrentSoldOut () {
      const { skuInfo, isSoldOut } = this
      return skuInfo.stock == 0 || isSoldOut
    },
    gallery() {
      const { main_image = {}, detail_image = [] } = this.curData
      const arr = [main_image]
      if (!detail_image?.length) return arr
      return arr.concat(detail_image)
    },
    showPromoLabel() {
      return this.config?.showPromoLabel || false
    },
    showBrand() {
      // 大促角标优先级最高
      return (!this.showPromoLabel && this.config?.showSeriesBrand) || false
    },
    showSeries() {
      return this.config?.showSeriesBrand || false
    },
    showBelt() {
      return this.config?.showBelt || false
    },
    promoLabelSrc() {
      return this.config.showPromoLabel && this.curData?.promoLabel?.src
    },
    // 新品牌 logo 数据源 shein使用
    brandLogoData() {
      const { brand, brand_badge_new } = this.curData || {}
      const { corner_img_left: src = '', name: alt } =
        brand || brand_badge_new || {}
      return { src, alt }
    },
    // 业务数据 预处理对象
    pretreatInfo() {
      return this.curData.pretreatInfo
    },
    showColorSize() {
      return this.bigCardStyle == 'showColorSize'
    },
    miniSizeLazyHeight() {
      const { showColor } = this
      const { skc_sale_attr = [] } = this.curData?.sale_attr_list
      if (skc_sale_attr?.length > 1) {
        return { style: showColor ? { height: '3.29rem' } : { height: '3.22rem' } }
      }
      return { style: showColor ? { height: '1.73rem' } : { height: '1.66rem' } }
    },
    retentionConfig() {
      let result = {
        show: false,
        contentText: this.language.SHEIN_KEY_PWA_24439
      }
      if (!this.config?.showBestDealLabel) return result
      const { realTimeTspLabels } = this.curData
      if (!realTimeTspLabels?.['3598']) return result
      result = {
        show: true,
        contentText: this.language.SHEIN_KEY_PWA_24439
      }
      return result
    },
  },
  watch: {
    productInfo: {
      handler(val) {
        this.currentGoodsId = val?.goods_id
        this.wishStatusMaps[this.currentGoodsId] = val?.wishStatus
      },
      immediate: true,
    },
  },
  methods: {
    addToBagSuccess(param) {
      this.$emit('addToBagSuccess', param)
    },
    setElAttr() {
      const locateLabels = this.curData?.mobileVerticalView?.locateLabels || {}
      const locateLabelSet = new Set(Object.keys(locateLabels).filter(key => Object.keys(locateLabels[key] || {}).length))
      const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet)
      const mixPromotionInfo = this.curData?.mixPromotionInfo || []

      const el = this.$refs['MINI_DETAIL_CONTAINER']
      el.setAttribute('data-sku', this.curData?.goods_sn || '')
      el.setAttribute('data-spu', this.curData?.productRelationID || '')
      el.setAttribute('data-id', this.curData?.goods_id || '')
      el.setAttribute('data-name', this.curData?.goods_name || '')
      el.setAttribute('data-rec_mark', this.curData?.ext?.rec_mark || '')
      el.setAttribute('data-extra_mark', this.curData?.ext?.extra_mark || '')
      el.setAttribute('data-other_ext_mark', this.curData?.ext?.other_ext_mark || '')
      el.setAttribute('data-other_d_ext_mark', this.curData?.dynamic_ext?.other_d_ext_mark || '')
      el.setAttribute('data-cat_id', this.curData?.cat_id || '')
      el.setAttribute('data-price', this.curData?.salePrice?.amount || '')
      el.setAttribute('data-us-price', this.curData?.salePrice?.usdAmount || '')
      el.setAttribute('data-us-origin-price', this.curData?.retailPrice?.usdAmount || '')
      el.setAttribute('data-index', this.index)
      el.setAttribute('data-type', this.curData?.dataSource || '') // config.moduleFrom == 'recommend'
      el.setAttribute('data-similar', this.isSoldOut && this.config.showSimilarBtn || '')
      el.setAttribute('data-reduce-price', !this.isSoldOut && this.pretreatInfo?.reducePrice?.usdAmount || '')
      el.setAttribute('data-soldout', Number(this.isSoldOut)) // data-soldout 目前只有在最近页面有用到
      el.setAttribute('data-sold-out', Number(this.isSoldOut)) // data-sold-out 需要用到其他页面上，因为上报的标识不一样，需要另外设置一个属性
      el.setAttribute('data-show-exclusive-price', this.exclusivePromotionPrice || '')
      el.setAttribute('data-lable', this.pretreatInfo?.label || '')
      el.setAttribute('data-series-brand', !this.promoLabelSrc && this.pretreatInfo?.seriesOrBrandAnalysis || '')
      el.setAttribute('data-brand', this.curData?.brand?.name || '')
      el.setAttribute('data-brand-code', this.curData?.brand?.brand_code || '')
      el.setAttribute('data-video', this.curData?.video_url ? 'video_icon' : '')
      el.setAttribute('data-spu-img', this.spuImg ? (this._showSpuImg ? 'spupic_1' : 'spupic_0') : '') // 有spu图且展示则上报spupic_1，有spu图但不展示则上报spupic_0，无spu图不上报
      el.setAttribute('data-mall_tag_code', `${this.pretreatInfo?.mallTagsInfo?.mall_tags || ''}_${this.curData?.mall_code || '-'}`)
      el.setAttribute('data-store_code', this.curData?.store_code ?? '')
      el.setAttribute('data-sale-attr', this.pretreatInfo?.saleAttr?.analysis || '')

      // el.setAttribute('data-show-promot-info', this.labelsShow.promotionLabel || '')
      el.setAttribute('data-price-cut', this.pretreatInfo?.priceCut || '')
      el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
      el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
      el.setAttribute('data-best-deal', this.retentionConfig.show ? 1 : '')
      el.setAttribute('data-promo-label', this.promoLabelSrc ? 1 : '')
      el.setAttribute('data-user-behavior-label', this.pretreatInfo?.mobileVerticalView?.userBehaviorLabel?.ana || '')
      el.setAttribute('data-ranking-list', this.rankingList?.ana || '')
      el.setAttribute('data-locate-labels', locateLabelsAna || '')
      el.setAttribute('data-belt-label', this.pretreatInfo?.mobileVerticalView?.beltLabel?.ana || '')
      const badges = []
      const dataBadges = el.getAttribute('data-badges')
      const upLeftBadges = this.goodsName && this.config.showTitle && this.frontTitle?.code || this.imgUpperLeft?.code
      if (dataBadges) badges.push(dataBadges)
      if (upLeftBadges) badges.push(upLeftBadges)
      el.setAttribute('data-badges', badges)
      // HOT标埋点用,大卡片不存在左上角情况(暂存需要判断左上角的逻辑)
      // let haveTopLeftBadge = this.curData?.mobileVerticalView?.locateLabels?.UPPER_LEFT_LABEL ?? false //左上角是否有角标(3p)
      // let showHotTag = this.curData?.tspLabels?.hot_color == 1 ? ( this.config?.showHotLocals ? 2 : 1) : 0 // 1左上，2名字前，0不展示
      let showHotTag = this.curData?.tspLabels?.hot_color == 1
      // const dataShowLabelHot = ((this.config.showTitle && this.curData?.goods_name && !this.frontTitle?.code && showHotTag === 2) || (!this.imgUpperLeft?.code && !haveTopLeftBadge && showHotTag === 1)) ? 'show_label_hot' : '' // 3p > new > hot, 只有真正展示hot标才上报埋点
      const dataShowLabelHot = (this.config.showTitle && this.curData?.goods_name && !this.frontTitle?.code && showHotTag) ? 'show_label_hot' : '' // new > hot, 只有真正展示hot标才上报埋点
      el.setAttribute('data-show-label-hot', dataShowLabelHot)

      if (this.config.showUserCommentKeywordsLabel && this.pretreatInfo?.mobileVerticalView?.userCommentKeywordsLabel?.labelLang) {
        el.setAttribute('data-user-comment-keywords-label', this.pretreatInfo.mobileVerticalView.userCommentKeywordsLabel?.ana || '')
      }
    },

    async getProduct(color) {
      this.currentGoodsId = color?.goods_id || color?.curGoodsId
      await this.$nextTick()
      if (this.wishStatusMaps[this.currentGoodsId] == undefined) {
        this.wishStatusMaps[this.currentGoodsId] = this.curData?.wishStatus
      }
    },
    formatDetail(goods) {
      const { siteMallInfo = [] } = goods
      const mallInfo = {}
      goods.mallList?.forEach(mall => {
        const price =
          goods?.mall_price?.find?.(_ => _.mall_code == mall.mall_code) || {}
        const stock =
          goods?.mall_stock?.find?.(_ => _.mall_code == mall.mall_code) || {}
        const tags =
          siteMallInfo?.find?.(_ => _.mall_code == mall.mall_code) || {}
        const appPromotion =
          goods?.mallAppPromotion?.filter?.(
            _ => _.mall_code == mall.mall_code
          ) || []
        const promotionInfo =
          goods?.mallPromotionInfo?.filter?.(
            _ => _.mall_code == mall.mall_code
          ) || []
        mall.mall_code_sort = mall.mall_sort
        mallInfo[mall.mall_code] = {
          ...tags,
          ...mall,
          ...price,
          ...stock,
          promotionInfo,
          appPromotion
        }
      })
      goods.mall = mallInfo
      return goods
    },
    sizeClickHandle(size, location = 'popup') {
      const { skuInfo, externalSizeInfoIndex, curSelectAttr } = size
      this.skuInfo = skuInfo
      this.externalSizeInfoIndex = externalSizeInfoIndex

      if (curSelectAttr.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              attr_value: curSelectAttr.attr_value_name,
              sku_code: skuInfo?.sku_code,
              review_location: '',
              location
            }
          }
        })
      }
    },
    clickGoodsHandle() {
      daEventCenter.triggerNotice({ 
        daId: '2-3-1',
        target: this.$refs['MINI_DETAIL_CONTAINER']
      })
      this.jump()
    },
    async jump() {
      const { langPath } = gbCommonInfo
      const { curData: { goods_img, goods_id, goods_url_name, cat_id } } = this
      const newUrl = await getProductDetailUrl({
        imgSrc: goods_img,
        langPath,
        goods_id,
        goods_url_name,
        cat_id
      })
      if (!window._gb_app_) {
        location.href = newUrl
      } else {
        this.$nextTick(() => {
          window._gb_app_.$router.push(newUrl)
        })
      }
    },
    updateWishHandle({ wishStatus }) {
      this.wishStatusMaps = {
        ...this.wishStatusMaps,
        [this.currentGoodsId]: wishStatus
      }
    },
    // 消耗性能组件延迟挂载 // Gallery // Size // Color
    initInstance() {
      //  this.$refs['MINI_GALLERY_REF']?.$children?.[0]?.init?.()
      // this.$refs['MINI_GALLERY_REF']?.init?.()
    }
  }
}
</script>

<style lang="less">
.mini-detail {
  &__container {
    position: relative;
    padding: 16/75rem;
    max-width: 100vw;
    box-sizing: border-box;
    background: @sui_color_black;
  }
  &__box {
    background: @sui_color_white;
    padding-bottom: 8/75rem;
    border-radius: 24/75rem;
  }
  &__sold {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: @zindex-out;
    .sold-tips {
      position: absolute;
      top: 3rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: @sui_color_white;
      &__icon {
        .font-dpr(160px);
        height: 160/75rem;
      }
      &__text {
        margin-top: 40/75rem;
        font-weight: 700;
        .font-dpr(32px);
        line-height: 40/75rem;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
