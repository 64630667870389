<template>
  <div
    ref="recommendFlowRoot"
    class="advertising-recommend"
  >
    <Tabs
      ref="recommendTabs"
      :tabs="tabInfo" 
      :is-show-search-input="isShowSearchInput"
      @changeSticky="isSticky=$event"
      @tabChange="handleTabChange"
    />
    <template v-for="item in products">
      <List
        v-show="item.itemId === activeTab"
        ref="refList"
        :key="item.itemId"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="loading"
        infinite-scroll-nodata="noMoreData"
        :infinite-scroll-distance="20"
        :tab="curTab && curTab.item_id"
        :products="item.list"
        :item-config="itemConfig"
        :language="language"
        :page-type="pageType"
      />
    </template>
    <div class="advertising-recommend__loading">
      <SLoading
        :type="loading ? 'pullup' : ''"
        :show="loading"
        cur-page-scroll-fix
      />
    </div>
    <div
      v-show="changeTypeLoading[activeTab]"
      class="advertising-recommend-tab__loading"
    >
      <SLoading
        :type="changeTypeLoading[activeTab] ? '' : 'curpage'"
        :show="changeTypeLoading[activeTab]"
        cur-page-scroll-fix
      />
    </div>
  </div>
</template>

<script>
import { storeModuleName } from '../../../product_app/store/modules/advertising'
import { mapState, mapMutations } from 'vuex'
import { getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { Loading as SLoading } from '@shein/sui-mobile'
import Tabs from './Tabs'
import List from './List'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
export default {
  name: 'AdvertisingRecommend',
  components: {
    Tabs,
    List,
    SLoading 
  },
  props: {
    pageType: {
      type: String,
      default: 'A'
    },
    isPaid: {
      type: [Boolean, Number],
      default: false
    },
    isShowSearchInput: {
      type: Boolean,
      default: false
    },
  },
  inject: ['advertisingAnalysis'],
  data() {
    return {
      tabInfo: [],
      products: {
        0: {
          itemId: 0,
          list: [],
          total: 0,
          page: 1,
        }
      },
      activeTab: 0, // 当前选中的tab id number 类型
      loading: false,
      isSticky: false,
      changeTypeLoading: {}
    }
  },
  computed: {
    ...mapState(storeModuleName, ['contextForSSR', 'abtInfo', 'language', 'itemConfig', 'hooksGoods', 'atomicFields', 'goodsItemInfo']),
    curTab() {
      return this.tabInfo.find(_ => _?.item_id == this.activeTab)
    },
    activeList() {
      return this.products[this.activeTab] || {}
    },
    noMoreData() {
      return this.activeList?.isEnd
    }
  },
  created() {
    this.initData(this.contextForSSR)
  },
  methods: {
    ...mapMutations(storeModuleName, ['changeFetchSearchTermsParams']),
    initData(contextForSSR) {
      // SSR
      if (contextForSSR) {
        const { goods, category } = contextForSSR
        const tabs = category.list || []
        const { list, promotionInfoFromServer } = goods || []
        this.initTab(tabs)
        this.setListData({
          key: this.activeTab,
          list: list || [],
          total: goods.total || 0,
          promotionInfoFromServer
        })

        if (typeof window !== 'undefined') {
          // 业务监控日志 - 页面打开成功次数累加
          metricPageSuccess({
            page: 'page_advertising',
            status: list?.length > 0 ? '1' : '0',
          })
        }
      }
    },
    // 初始化tab
    initTab(tabs) {
      this.tabInfo = [{
        item_id: 0,
        item_name: this.language.SHEIN_KEY_PWA_24606,
      }, ...tabs]
    },
    // 设置列表数据
    setListData({ key, page = 1, list, total, promotionInfoFromServer }) {
      this.formatGoodsData(list, promotionInfoFromServer)
      const preList = this.products[key]?.list || []
      const curAllList = preList.concat(list)
      this.products[key].page = page
      this.products[key].total = list.length ? total : 0 // 如果没有商品了，当成没有总数了（相当于加载结束. viewAll 是否展示判断
      this.products[key].list = curAllList
      this.products[key].isEnd = (list.length == 0) || (curAllList.length >= total)
    },
    // 初始化列表信息
    presetListInfo(key) {
      // 1. 当前展示的tab
      this.activeTab = key
      // 2. 如果当前
      const hadInit = !!this.products[key]
      if (!hadInit) {
        this.$set(this.products, key, {
          itemId: key,
          page: 1,
          list: [],
          total: 0,
        })
      }
      return {
        hadInit,
      }
    },
    // tab切换前
    tabClickPreChange() {
      const $rootEl = this.$refs['recommendFlowRoot']
      const top = $rootEl.getBoundingClientRect().top
      // 顶部栏高度
      const headerHight = document?.querySelector?.('.j-common-header-dom')?.getBoundingClientRect()?.height || 0
      // 当前是否已经吸顶
      return top <= headerHight
    },
    // tab切换后
    tabClickDone() {
      if (!this.isSticky) return
      // tab高度
      const tabHight = this.$refs?.recommendTabs?.$el?.getBoundingClientRect()?.height || 0
      const recommendComponentHight = this.$refs['recommendFlowRoot']?.offsetTop || 0
      window.scroll({
        top: recommendComponentHight - tabHight, // 吸顶以后tab sticky布局了 所以要下滑一个tab的距离
      })

    },
    // 控制tab切换
    async handleTabChange(key) {
      // 参见需求:pageId=1500482628
      this.isShowSearchInput && this.changeFetchSearchTermsParams({ key: 'cat_id', value: key || '' })
      this.isShowSearchInput && this.changeFetchSearchTermsParams({ key: 'goods_id', value: '' })
      this.isShowSearchInput && this.changeFetchSearchTermsParams({ key: 'list_scene', value: 'outside_station_search' || '' })
      this.tabClickPreChange()
      const { hadInit } = this.presetListInfo(key)
      // 2. 当前tab对应的商品信息请求
      if (!hadInit) await this.getRecProductList({ key, changeTab: 1 })
      this.tabClickDone()
    },
    // 点击加载更多
    loadMore() {
      const key = this.activeTab
      // 当前的tab
      const curProductInfo = this.products[key]
      // 请求下一页
      !curProductInfo.isEnd && this.getRecProductList({ key, page: curProductInfo.page + 1 })
    },
    // 获取推荐商品列表
    async getRecProductList({ key, page = 1, changeTab = 0 } = {}) {
      this.loading = true
      changeTab && (this.changeTypeLoading[key] = true)
      // 1. 拿商品
      const params = {
        goods_id: getQueryString({ key: 'goods_id' }),
        tsp_id: getQueryString({ key: 'tsp_id' }),
        crowd_id: getQueryString({ key: 'crowd_id' }),
        cate_id: this?.hooksGoods?.cat_id,
        pageNum: page,
        requestType: 'nextpage',
        isPaid: +this.isPaid,
      }
      if (key) {
        params.filter_cate_id = key
      }
      const { goods } = await schttp({
        url: '/api/global/marketing/get',
        method: 'get',
        params,
      })
      let { list, total, promotionInfoFromServer } = goods || {}
  

      // 第一页 第一个tab（itemId = 0） 没商品
      if (page == 1 && key == 0 && list.length == 0) {
        this.loading = false
        return // TODO
      }
      // 2.设置数据
      await this.setListData({
        key,
        page,
        list,
        total, // 商品的总数
        promotionInfoFromServer
      })
      // 等到下方视图更新了再设置loading
      await this.$nextTick()
      this.loading = false
      changeTab && (this.changeTypeLoading[key] = false)
    },
    // 格式化商品项入参
    formatGoodsData (list, promotionInfoFromServer) {
      return dealProductsPretreatInfo({
        products: list || [],
        itemConfig: this.itemConfig,
        language: this.language,
        promotionInfoFromServer

      })
    },
    resetAnalysis () {
      this.$refs.refList?.forEach?.(_ => _?.resetAnalysis?.())
    }
  }
}
</script>

<style lang="less">
.advertising-recommend {
  // margin-top: .0267rem;
  background: #ffffff;
  &__loading {
    height: .8rem;
  }
  .advertising-recommend-tab__loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
