<template>
  <section class="advertising-page">
    <div class="advertising-page-banner">
      <!-- 顶部图 只渲染一次即可 一个站点只有一张 -->
      <Banner
        v-once
        :src="bannerImg"
      />
    </div>
    <!-- v-show是为了解决ssr 水合问题, autoFetch参数解决不展示请求问题 -->
    <div 
      v-show="isShowSearchInput"
      class="advertusing-page-search"
    >
      <SSticky
        class="recommend-tabs__sticky"
        :type="'fixed'"
        :offset="1.17"
        :style="{
          'z-index': 9
        }"
      >
        <DefaultSearchBox
          ref="defaultSearchBoxRef"
          box-type="round"
          search-button-type="round"
          show-search-button
          :auto-fetch="isShowSearchInput"
          :fetch-search-terms-params="fetchSearchTermsParams"
          @searchButtonClick="handleSearchButtonClick"
          @searchTermClick="handleSearchTermClick"
          @searchTermTransitionEnd="searchTermTransitionEnd"
          @resolveSearchTerms="resolveSearchTerms"
        />
      </SSticky>
    </div>
    <!-- 大卡片 -->
    <div
      v-if="isShowBigCard"
      class="advertising-page-card"
    >
      <BigCard 
        ref="refBigCard"
      />
    </div>
    <div class="advertising-page-recommend">
      <!-- 推荐流 -->
      <AdvertisingRecommend
        ref="refRecommend"
        :page-type="pageType"
        :is-show-search-input="isShowSearchInput"
        :is-paid="sheinClubInfo.isPaid"
      />
    </div>
    <div
      v-once
      class="advertising-page-suspension"
      :language-home="language.SHEIN_KEY_PWA_25654"
    >
      <HomeIcon />
    </div>
  </section>
</template>

<script>
import storeModule, {
  storeModuleName
} from '../product_app/store/modules/advertising'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import AdvertisingRecommend from './components/AdvertisingRecommend/Index.vue'
import Banner from './components/Banner'
import HomeIcon from './components/HomeIcon'
import BigCard from './components/BigCard'
import { DefaultSearchBox } from '@shein-aidc/bs-search-box-mobile-vue2'
import { Sticky as SSticky } from '@shein/sui-mobile'

import hiddenBackTopMixins from 'public/src/pages/advertising/mixins/hiddenBackTopMixins'
import advertisingAnalysis from './analysis'
import analysis from 'public/src/pages/common/search_words/analysis/index'

import { checkGoodsStock } from './common/utils'
import { cloneDeep, getQueryString, stringifyQueryString } from '@shein/common-function'

import { onClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOn'
import { offClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOff'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'

const exposeSearchTextArr = []
export default {
  name: 'AdvertisingPage',
  components: {
    Banner,
    AdvertisingRecommend,
    HomeIcon,
    BigCard,
    DefaultSearchBox,
    SSticky
  },
  mixins: [hiddenBackTopMixins],
  provide () {
    return {
      advertisingAnalysis,
      getIsSupportCropImage: () => true
    }
  },
  // SSR 调用 将数据注入仓库
  asyncData ({ store, context }) {
    if (!store.state[storeModuleName]) {
      store.registerModule(storeModuleName, storeModule)
      if (context?.cat_info?.pageName === 'page_advertising') {
        // 首屏数据不做变更 但是不能直接冻结context 因为vue在ssr时还会对context对象做一些拓展
        const contextValue = Object.freeze(cloneDeep(context))
        store.state[storeModuleName].contextForSSR = Object.freeze(contextValue)
      }
    }

  },
  data() {
    return {
      isFirst: true, // 首次进入
      defaultWords: [], // 底纹词数组
    }
  },
  computed: {
    ...mapState(storeModuleName, ['pageType', 'hooksGoods', 'locals', 'language', 'sheinClubInfo', 'catInfo', 'fetchSearchTermsParams']),
    ...mapGetters(storeModuleName, ['bannerImg']),
    isShowBigCard() {
      // A分支 有勾子商品 且在售
      return this.pageType === 'A' && this?.hooksGoods?.goods_id && checkGoodsStock(this?.hooksGoods) && this?.hooksGoods?.goods_name
    },
    // 参见需求:pageId=1500482628
    isShowSearchInput() {
      return this.pageType === 'B' && getQueryString({ key: 'search' }) === 'show'
    }
  },
  created() {
    const { contextForSSR } = this.$store.state[storeModuleName] || {}
    if (contextForSSR) {
      this.resetLocals(contextForSSR)
      this.initData(contextForSSR)
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
    } else {
      // 先直接刷新页面，这样走兜底逻辑,后续在考虑优化，走spa发请求首屏
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  },
  activated() {
    if (!this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()

      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}
      detailBackGoodsId && this.changeFetchSearchTermsParams({ key: 'goods_id', value: detailBackGoodsId })
      detailBackCatId && this.changeFetchSearchTermsParams({ key: 'cat_id', value: detailBackCatId })
      window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
    }
    this.isFirst = false
    appEventCenter.$emit('pageOnload')
  },
  mounted() {
    onClickProductCardInGoodsDetail((args) => {
      window.sessionStorage?.setItem('userClickProductLatest', JSON.stringify(args))
    })
    if (this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()
    }
  },
  beforeDestroy() {
    offClickProductCardInGoodsDetail()
  },
  methods: {
    ...mapMutations([
      'assignRootState',
    ]),
    ...mapActions(storeModuleName, [
      'initState'
    ]),
    ...mapMutations(storeModuleName, [
      'setHooksGoods',
      'setAbtInfo',
      'setPageType',
      'initItemConfig',
      'setLanguage',
      'resetLocals',
      'changeFetchSearchTermsParams'
    ]),
    resolveSearchTerms(keywords, result) {
      // 曝光第一条数据
      this.defaultWords = keywords
      if(this.defaultWords.length === 0) {
        analysis.exposeSearch({
          defaultWords: '',
          placeholder: '',
          localIndex: 0,
        })
        return
      }
      window.sessionStorage.setItem('advertisingDefaultWords', JSON.stringify(keywords ?? []))
      // 为了处理：UG-148607
      const ref = this.$refs.defaultSearchBoxRef
      if (ref && ref.updateSwiper) {
        ref.updateSwiper()
      }
      if(exposeSearchTextArr.includes(keywords[0]?.word) || !keywords[0]?.word) {
        return
      }
      
      analysis.exposeSearch({
        defaultWords: [keywords[0]],
        placeholder: keywords[0],
        localIndex: 0,
      })
      exposeSearchTextArr.push(keywords[0]?.word)
    },
    // 轮播回调曝光
    searchTermTransitionEnd(item, index) {
      if(exposeSearchTextArr.includes(item?.word)) {
        return
      }
      analysis.exposeSearch({
        defaultWords: [item],
        placeholder: this.defaultWords[0],
        localIndex: index,
      })
      exposeSearchTextArr.push(item?.word)
      return
    },
    // 点击跳转搜索结果页
    handleSearchButtonClick(item, index) {
      // 如果底纹词为空，跳转预搜页
      if(!item) {
        this.handleSearchTermClick(null) // 传null, 是保证为空时和直接点击搜索框跳预搜页的传值一样
        return
      }
      let payload = { item, index }
      analysis.search({
        keyword: item?.word || '-',
        full_name: '-',
        result_type: 3,
        search_redir: '-',
        data: {
          position: payload?.index + 1,
          type: item?.type ?? '-',
        },
        crowdId: item?.crowdId || '-',
        originWord: item?.word || '-',
        pageType: 'all',
        trend_list_content: '-',
      })
      // 设置点击搜索按钮直接跳转搜索结果页pagefrom埋点问题
      searchWordsGlobalAnalysis.set({
        result_type: 3,
        result_word: item?.word,
        d: 0,
        parent_page: SaPageInfo?.page_name,
        entrancetype: SaPageInfo?.page_param?.entrancetype || '-',
      })

      const currentSearchTermWord = payload?.item?.word
      const currentSearchTermIndex = payload?.index
      const params = {
        src_identifier: `st=${3}\`sc=${currentSearchTermWord}\`sr=0\`ps=${currentSearchTermIndex + 1}`,
        src_module: 'search',
        src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
      }
      const url = `${this.langPath}/pdsearch/${encodeURIComponent(currentSearchTermWord)}/?${stringifyQueryString({ queryObj: params })}`
      this.$router.push(url)
    },
    // 点击跳转预搜页（从header的input扣出来的跳转预搜逻辑）
    handleSearchTermClick(searchTerm) {
      let payload = {
        placeholder: searchTerm,
      }
      analysis.clickSearch(payload)
      // 将当前轮换的默认词，显示在搜索页输入框内
      if (payload?.placeholder) {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          currentDefaultKeyword: payload.placeholder.word,
          searchSelectKeyWord: '',
          mixedKeyword: '',
          inputDefaultWordsIndex: this.defaultWords?.findIndex(i => i?.word === payload.placeholder?.word)
        })
      } else {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          mixedKeyword: '',
          searchSelectKeyWord: '',
          currentDefaultKeyword: ''
        })
      }

      // 标识进入新页面
      this.$route.meta.isNewPage = true
      this.$route.meta.cameraFrom = null
      const pagefrom = window?.SaPageInfo?.page_name
      const query = {
        pageType: 'all',
        pagefrom
      }

      if (this.isGoodsDetailPage) {
        Object.assign(query, {
          goods_id: this.goodsDetailInfo?.goodsId,
          cate_id: this.goodsDetailInfo?.catId
        })
      }

      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        query.src_identifier = src_identifier
      }
      
      this.$router.push({
        path: `${this.langPath}/presearch`,
        query
      })
  
    },
    initData(payload) {
      payload && this.initState(payload)
    },
    resetAnalysis () {
      advertisingAnalysis.sendPV()
      this.$refs.refBigCard?.resetAnalysis?.()
      this.$refs.refRecommend?.resetAnalysis?.()
    }
  }
}
</script>
<style lang="less" scoped>
.advertusing-page-search {
  --search-box-border-color: #000000;
  --search-box-box-height: 34px;
  // padding: 0 10/37.5rem;
  background-color: #fff;
  .recommend-tabs__sticky {
    /* 因为置顶逻辑，这里只能写死8px的绝对值，使用相关单位，因为转化问题会存在误差，导致搜索框和tab之前存在间隙，可以参见UI库dpr.js中的源码 */
    padding-top: 8px;
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: 10/37.5rem !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: 10/37.5rem !important;
    background-color: #fff;
  }
}
</style>
